import React, { useState, useRef, useEffect } from "react";
import style from "./partnerPage.module.css";
import BlockPartnerWithUs from "./partner_page_components/BlockPartnerWithUs";
import BlockHowItWorks from "./partner_page_components/BlockHowItWorks";
import BlockPerfectFor from "./partner_page_components/BlockPerfectFor";
import BlockAlreadyWithUs from "./partner_page_components/BlockAlreadyWithUs";
import BlockContactUs from "./partner_page_components/BlockContactUs";

function PartnersPage() {
  return (
    <div className={style.mainContainer}>
      <div className={style.whiteBlock} />
      <div className={style.purpleBlock} />
      <div className={style.whiteBlock2} />
      <div className={style.orangeBlock} />
      <div className={style.endWhiteBlock} />
      <div className={style.crossBlock}>
        <BlockPartnerWithUs />
        <BlockHowItWorks />
        <BlockPerfectFor />
        <BlockAlreadyWithUs />
        <BlockContactUs />
      </div>
    </div>
  );
}

export default PartnersPage;
