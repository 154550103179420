import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import styles from "../partnerPage.module.css";
import { getAllPartners } from "../../../features/user_synthetic/userSyntheticSlice";

export default function BlockAlreadyWithUs() {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [partners, setPartners] = useState([]);
  const totalItems = partners.length;
  console.log(partners);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalItems - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalItems - 1 ? 0 : prevIndex + 1));
  };

  const getVisibleItems = () => {
    const prevItem = (currentIndex === 0 ? totalItems : currentIndex) - 1;
    const nextItem = (currentIndex + 1) % totalItems;

    return [partners[prevItem], partners[currentIndex], partners[nextItem]];
  };

  const visibleItems = getVisibleItems();
  console.log(visibleItems);

  const getPartners = useCallback(async () => {
    const allPartners = await dispatch(getAllPartners());
    setPartners(allPartners.payload);
  }, [dispatch]);

  useEffect(() => {
    console.log("useEffect");
    getPartners();
  }, [getPartners]);

  return (
    <div>
      <div className={styles.mainAlreadyWithUs}>
        <h2 className={styles.headerAlreadyWithUs}>Already With Us</h2>
        <div className={styles.carouselContainer}>
          {visibleItems.map((item, index) => {
            return (
              <div className={index === 1 ? styles.partnerBlockCenter : styles.partnerBlockBorder} key={index}>
                {partners.length !== 0 && <h2>{item.name}</h2>}
                {partners.length !== 0 && <img className={styles.imgStyle} src={`data:image/*;base64,${item.image}`} alt={item.name} />}
                {partners.length !== 0 && (
                  <ul className={styles.descriptionBlock}>
                    <li className={styles.description}>{item.firstDescription}</li>
                    <li className={styles.description}>{item.secondDescription}</li>
                    <li className={styles.description}>{item.thirdDescription}</li>
                  </ul>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={handlePrev} className={styles.navBtn}>
            ❮
          </button>
          <div className={styles.between} />
          <button onClick={handleNext} className={styles.navBtn}>
            ❯
          </button>
        </div>
      </div>
    </div>
  );
}
