// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { hidePopup, setVideoFile, showPopup } from "./syntheticSlice";
import "./synthetic.css";

function Synthetic({
  greetingVideo = "",
  videoClick = {
    videoPath: "",
    question: false,
  },
  onVideo = {
    videoPath: "",
    question: false,
  },
  onContinueClick = {
    videoPath: "",
    question: false,
  },
}) {
  const [config, setConfig] = useState(null);
  const navigate = useNavigate();
  const isPopupVisible = useSelector((state) => state.synthetic.isPopupVisible);
  const videoFile = useSelector((state) => state.synthetic.videoFile);
  const dispatch = useDispatch();
  const location = useLocation();
  const videoRef = useRef(null);
  const animationClass = config?.position === "right" ? "animate-right" : "animate-left";
  const animationExitClass = config?.position === "right" ? "animate-out-right" : "animate-out-left";
  const [autoPlay, setAutoPlay] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [isCloseIconVisible, setIsCloseIconVisible] = useState(false);
  const [greetingVideoWatched, setGreetingVideoWatched] = useState(false);
  const [isFixedToFooter, setIsFixedToFooter] = useState(false);
  const [valueBottom, setValueBottom] = useState(0);

  const appendCacheBuster = (url) => {
    if (!url) return url;
    const cacheBuster = `nocache=${new Date().getTime()}`;
    return url.includes("?") ? `${url}&${cacheBuster}` : `${url}?${cacheBuster}`;
  };

  const onClose = useCallback(() => {
    if (videoFile === greetingVideo) {
      setGreetingVideoWatched(true);
    }
    dispatch(hidePopup());
  }, [dispatch, videoFile, greetingVideo]);

  const handleGreetingVideo = useCallback(() => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      if (video.id !== greetingVideo) {
        video.pause();
      }
    });
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(greetingVideo);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
  }, [dispatch, greetingVideo]);

  const handleOnClick = useCallback(() => {
    if (!videoClick.videoPath) return;
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(videoClick.videoPath);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
  }, [dispatch, videoClick]);

  const handleOnVideo = useCallback(() => {
    if (!onVideo.videoPath) return;
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(onVideo.videoPath);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
  }, [dispatch, onVideo]);

  const handleContinueClick = useCallback(() => {
    if (!onContinueClick.videoPath) return;
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(onContinueClick.videoPath);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
    setShowContinueButton(false);
  }, [dispatch, onContinueClick]);

  const handleVideoEnded = useCallback(() => {
    if (greetingVideo !== "" || !greetingVideoWatched) {
      onClose();
      setAutoPlay(false);
      setGreetingVideoWatched(true);
      return;
    }
    if (videoFile !== videoClick.videoPath) {
      if (videoClick.question) {
        setIsCloseIconVisible(false);
      } else {
        onClose();
      }
    }
  }, [onClose, greetingVideo, greetingVideoWatched, videoFile, videoClick]);

  const handleVideoLoaded = () => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      videoRef.current.play().catch((error) => {
        onClose();
        setGreetingVideoWatched(true);
        console.error("Error by load video:", error);
      });
    }
  };

  const handleButtonClick = (buttonType) => {
    switch (buttonType) {
      case "Partners":
        navigate("/partners");
        break;
      case "Prices":
        navigate("/pricing");
        break;
      case "Works":
        navigate("/howitwork");
        break;
      case "TestLanguages":
        navigate("/testyourlanguage");
        break;
      case "ContactUs":
        navigate("/contactUs");
        break;
      default:
        console.log("Unknown button clicked");
    }
  };

  const getButtonConfig = () => {
    if (location.pathname === "/partners") {
      return [
        { label: "Go to Pricing", type: "Prices" },
        { label: "How it Works", type: "Works" },
      ];
    }
    if (location.pathname === "/pricing") {
      return [
        { label: "For Partners", type: "Partners" },
        { label: "How it Works", type: "Works" },
        { label: "Contact Us", type: "ContactUs" },
      ];
    }
    if (location.pathname === "/howitwork") {
      return [
        { label: "For Partners", type: "Partners" },
        { label: "Pricing", type: "Prices" },
        { label: "> 22 Dialects", type: "TestLanguages" },
      ];
    }
    return [
      { label: "For Partners", type: "Partners" },
      { label: "Pricing", type: "Prices" },
      { label: "How it Works", type: "Works" },
    ];
  };

  const buttons = getButtonConfig();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setConfig({
          avatar: "/syntheticAvatar.webp",
          position: "right",
        });
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    if (onVideo.videoPath !== "") {
      handleOnVideo();
    } else {
      dispatch(hidePopup());
    }
  }, [onVideo, handleOnVideo, dispatch]);

  useEffect(() => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
      videoRef.current.load();
    }
  }, [videoFile]);

  useEffect(() => {
    dispatch(hidePopup());
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footerBox");
      const footerOffsetTop = footer?.offsetTop || 0;
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;

      if (scrollTop + windowHeight >= footerOffsetTop) {
        setIsFixedToFooter(true);
        setValueBottom(scrollTop + windowHeight - footerOffsetTop);
      } else {
        setIsFixedToFooter(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isPopupVisible ? (
    <div
      className={`synthetic-popup ${config?.position} ${isPopupVisible ? animationClass : animationExitClass}`}
      style={{ bottom: isFixedToFooter ? `${valueBottom}px` : "3%" }}
    >
      <div className="buttons-container">
        {buttons.map((button, index) => (
          <button
            key={button.type}
            type="button"
            className={`custom-button button-${index + 1}`}
            onClick={() => handleButtonClick(button.type)}
          >
            {button.label}
          </button>
        ))}
      </div>

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={videoFile}
        preload="metadata"
        playsInline
        poster="/Dual-Ring.png"
        ref={videoRef}
        onLoadedData={handleVideoLoaded}
        autoPlay={autoPlay}
        className="avatar-video"
        onEnded={handleVideoEnded}
      />

      <div
        className="popup-controls"
        role="button"
        tabIndex={0}
        onClick={onClose}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onClose();
          }
        }}
      >
        {isCloseIconVisible && <i className="cl-btn-7" />}
      </div>

      {showContinueButton && (
        <div className="continue-button-container">
          <button type="button" className="continue-button" onClick={handleContinueClick}>
            Continue
          </button>
        </div>
      )}
    </div>
  ) : (
    <div
      className="pulsar-container"
      role="button"
      tabIndex={0}
      onClick={handleOnClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleOnClick();
        }
      }}
    >
      <button
        type="button"
        className={`round-button ${isPopupVisible ? "hidden" : ""}`}
        style={{ bottom: isFixedToFooter ? `${valueBottom}px` : "3%" }}
      >
        <img src={config?.avatar} alt="Toggle Popup" />
      </button>
    </div>
  );
}

Synthetic.propTypes = {
  greetingVideo: PropTypes.string,
  videoClick: PropTypes.shape({
    videoPath: PropTypes.string,
    question: PropTypes.bool,
  }),
  onVideo: PropTypes.shape({
    videoPath: PropTypes.string,
    question: PropTypes.bool,
  }),
  onContinueClick: PropTypes.shape({
    videoPath: PropTypes.string,
    question: PropTypes.bool,
  }),
};

Synthetic.defaultProps = {
  greetingVideo: "",
  videoClick: {
    videoPath: "",
    question: false,
  },
  onVideo: {
    videoPath: "",
    question: false,
  },
  onContinueClick: {
    videoPath: "",
    question: false,
  },
};

export default Synthetic;
