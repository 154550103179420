import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import styles from "../partnerPage.module.css";
import Button from "../../button/Button";
import buttonStyle from "../../button/button.module.css";
import { sendEmailAboutPartner } from "../../../features/user_synthetic/api";

function BlockContactUs() {
  const dispatch = useDispatch();
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.trim();
    const isValidEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(emailValue);
    setEmail(emailValue);
    setEmailError(!isValidEmail);
  };

  const handleNameChange = (e) => {
    const nameValue = e.target.value.trim();
    const isValidName = /^[a-zA-Z\u00C0-\u00FF\s'-]+$/.test(nameValue);
    setName(nameValue);
    setNameError(!isValidName);
  };

  const checkFullCorrect = () => {
    return name && !nameError && email && !emailError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const contactInfo = {
      name,
      email,
    };

    await dispatch(sendEmailAboutPartner(contactInfo));
  };

  return (
    <div>
      <div className={styles.mainContactUs}>
        <div className={styles.globalTextContainer}>
          <h2 className={styles.headerContactUs}>Ready to take your agency to the next level? </h2>
          <div className={styles.otherTextContainer}>
            <h3 className={styles.otherTextContactUs}>
              Contact us today to learn more about our partnership opportunities.
            </h3>
          </div>
          <div className={styles.mainForm}>
            <div className={styles.containerInputs}>
              <div className={styles.formGroup}>
                <label className={styles.labelText} htmlFor="name">
                  Your name
                </label>
                <input
                  className={styles.inputText}
                  type="name"
                  id="name"
                  name="name"
                  placeholder="-"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
                {emailError && (
                  <p className={styles.lineCheckValidPass}>
                    <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                    <span className={styles.errorMessage}>Wrong name format</span>
                  </p>
                )}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.labelText} htmlFor="email">
                  E-mail for communication
                </label>
                <input
                  className={styles.inputText}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="-"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                />
                {emailError && (
                  <p className={styles.lineCheckValidPass}>
                    <span className={`${styles.iconCheckPass} ${styles.cross}`} />
                    <span className={styles.errorMessage}>Wrong email format</span>
                  </p>
                )}
              </div>
            </div>
            <div className={styles.whitePart}>
              <div className={styles.submitButtonContainer}>
                <Button
                  buttonName="Send"
                  buttonType={checkFullCorrect() ? "submit" : "button"}
                  onClick={handleSubmit}
                  className={`${buttonStyle.buttonSave} ${styles.submitButton} ${checkFullCorrect() ? "" : styles.noActive}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockContactUs;
