import React from "react";
import style from "../partnerPage.module.css";

function BlockPartnerWithUs() {
  return (
    <div>
      <div className={style.block}>
        <h2 className={style.mainHeader}>Why Partner With Us?</h2>
        <div className={style.blocks}>
          <div className={style.block1}>
            <div className={style.imgContainer}>
              <img className={style.pic1} src="/coin_1.svg" />
            </div>
            <div className={style.paragraph}>
              <b>Enhance Your Clients' Campaigns: </b> Our tool seamlessly integrates with landing pages and websites,
              driving better engagement and conversion rates.
            </div>
          </div>
          <div className={style.block2}>
            <div className={style.imgContainer}>
              <img className={style.pic2} src="/coin_2.svg" />
            </div>
            <div className={style.paragraph}>
              <b>Expand Your Service Portfolio:</b> Offer a unique, high-value solution that sets you apart from
              competitors.
            </div>
          </div>
          <div className={style.block3}>
            <div className={style.imgContainer}>
              <img className={style.pic3} src="/coin_3.svg" />
            </div>
            <div className={style.paragraph}>
              <b>Boost Your Bottom Line:</b> Enjoy attractive revenue sharing on all client subscriptions you bring in.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockPartnerWithUs;
